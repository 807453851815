/* This script adds an event handler to hide submit buttons for the shift status form
 * and then automatically submit the form when the status button is clicked.
 */
var shiftStatusScripts = {

  hideSubmit: function() {
    $('#status-submit').hide();
    $('.status-submit').hide();
  },

  SubmitFormWhenChecked: function() {
    this.form.submit();
    return true;
  }

};

document.addEventListener("turbo:load", function() {
  shiftStatusScripts.hideSubmit();
  x = document.querySelectorAll('.shift-status-button').forEach(function(e) {
    e.addEventListener('click', shiftStatusScripts.SubmitFormWhenChecked);
  });

});
