$(document).ready(function() {
    $(ReportsScripts.setup);
});


var ReportsScripts = {
    setup: function() {
        ReportsScripts.hideNonFortuneLines();
    },



  hideNonFortuneLines: function function_name() {
    $(document).on('click', '.fortune-tag', function() { ReportsScripts.testThis($(this)); } );
  },

  hideFortuneShiftLines: function (tag) {
    $('.fortune-count').parents('tr').hide();
  },

  testThis: function (tag) {
    $('.report_line').toggle();
    $('.fortune-count').parents('tr').show();
  }





};

